import { TwoSwitch } from "../../lib/iprs-react-library/src/index";


// report status matching HealthcareDelivery.ReportStatus
const REP_STATUS_CHECK      = 2;
const REP_STATUS_PUBLISHED  = 4;

/**
 *  React component to display when there is a reason the form cannot be submitted 
 * @param {object} props
 * @returns 
 */
const BlockingMessage = ({isInitial, isPharos, titleText, message}) => {

    const initialOrFollowupTxt = isInitial? 'Initial ':'Follow Up ';

    return <div class="patient-overview">
    <div className="patient-overview__title-bar">
        <div className="typography">
            <h1>Submit {initialOrFollowupTxt}Forms Due</h1>
        </div>
    </div>

    <div className="patient-overview__section">
        <TwoSwitch test={titleText} >
            <h2 className="patient-overview__section-title">This {initialOrFollowupTxt}{titleText}</h2>
        </TwoSwitch>
        <div className="typography typography--large">
            <TwoSwitch test={message} >
                <p>{message}</p>
            </TwoSwitch>
            <TwoSwitch test={!isPharos} ><p>
                <a className="button" href="/patients/patient-overview/">Patient Overview</a>
            </p></TwoSwitch>
        </div>
    </div>
</div>
}

/**
 * Gets the field values to display as a message to the user
 * for different cominations of report status and isPharos
 * @param {boolean} isPharos 
 * @param {BigInteger} reportStatus 
 * @param {boolean} isInitial 
 * @returns 
 */
const getBlockingMessage = (isPharos, reportStatus, isInitial, submitted, errorMsg) => {

    let blockingMessage = null;
    if(submitted){
        blockingMessage = {
            titleText: 'Form Submitted Successfully',
            message: 'Thank you for submitting a Follow Up form' + (isPharos? '.': ', click the button below to return to the patient overview.')};
    }else if(REP_STATUS_PUBLISHED === reportStatus){
        blockingMessage = {
            titleText: 'Form is allready published',
            message: 'Published forms cannot be edited'};
    }else if (!isPharos && REP_STATUS_CHECK === reportStatus){
        blockingMessage = {
            titleText: 'Form is ready for checking',
            message: 'You cannot edit forms that have been sent for checking'};
    }else if ( errorMsg){
        blockingMessage = {
            titleText: 'Error',
            message: errorMsg};
    }
    return {...blockingMessage, isPharos, isInitial};
}

export default BlockingMessage;
export { getBlockingMessage };