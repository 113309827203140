import React, {useEffect, useState} from 'react';
import {GoogleMap, Marker, InfoWindow} from "@react-google-maps/api";

import home_icon from "./img/new/home-icon.svg";
import briefcase_icon from "./img/new/briefcase-icon.svg";
import location_pin from "./img/new/location-pin-icon.svg";

// import Map from './Map';

const OnwardReferralMap = ({
    userLocations, 
    postcodeCheckbox, 
    searchResultWithMarker, 
    referralMessageHandler
    }) => {
        
    // useLoadScript is used previously but trying out <LoadScript> instead.
    // To get the GoogleMap to update its center when API response is received.
    // Currently, it doesn't seem to refresh the map when state that contains coordinate is
    // updated and just uses the initial value set for the state.
    // Apparently according to bard and chatgpt, <loadScript> is simpler to use than useLoadScript
    // and when the components are encapsulated within <loadScript></loadScript> will re-render 
    // when changes are made in the encapsulated components.
    // const { isLoaded } = useLoadScript({
    //     googleMapsApiKey: api_key
    // });

    const [mapCenter, setMapCenter] = useState({lng: 0, lat: 0});

    const [userLocationMarkers, setUserLocationMarkers] = useState([]);

    // /This useEffect will run whenever userLocations state has been changed and plot the marker
    // accordingly when they are available.
    useEffect(()=>{
        const userMarkers = []
        if(userLocations !== undefined){
            if(userLocations.home.coordinate !== ''){
                userMarkers.push({markerType: 'home' ,coordinate: userLocations.home.coordinate});
            }
            if(userLocations.work.coordinate !== ''){
                userMarkers.push({markerType: 'work' ,coordinate: userLocations.work.coordinate});
            }
            if(userLocations.alt.coordinate !== ''){ 
                userMarkers.push({markerType: 'alt' ,coordinate: userLocations.alt.coordinate});
            }
        }
        setUserLocationMarkers(userMarkers);
    },[userLocations])

    useEffect(()=>{
        // When all checkboxes in the search component is false:
        // - If only home is available
        // - If only work is available
        // - If home and work is both available
        // - If alt is available.

        // This code will run once when this component loads and all the required data is set.
        // This code is used to center the map using coordinates available.
        // if only homePostcode is checked, it will be centered with home coordinates values.
        // if only workPostcode is checked, it will be centered with work coordinates values.
        // if homePostcode and workPostcode is checked, it should be centered between the two
        // if altPostcode is checked, the input to enter the postcode will appear and coordinate will not be known until search request is made.
        //   -  
        if(
            postcodeCheckbox.homePostcode === true &&
            postcodeCheckbox.workPostcode === true &&
            postcodeCheckbox.altPostcodeCheckbox === false
        ){
            if(
                userLocations.home.coordinate !== '' &&
                userLocations.work.coordinate !== '' &&
                userLocations.alt === ''
            ){
                const bounds = new window.google.maps.LatLngBounds();

                let markers = []

                markers.push(userLocations.home.coordinate);
                markers.push(userLocations.work.coordinate);

                markers.forEach((c)=>{
                    if(c !== undefined){
                        bounds.extend({lat: c.lat,lng: c.lng })
                    }
                });
                const center = bounds.getCenter();

                setMapCenter(center);
            } else if (
                userLocations.home.coordinate !== '' &&
                userLocations.work.coordinate === '' &&
                userLocations.alt === ''
            ){
                setMapCenter(userLocations.home.coordinate);
            } else if (
                userLocations.home.coordinate === '' &&
                userLocations.work.coordinate !== '' &&
                userLocations.alt === ''
            ){
                setMapCenter(userLocations.work.coordinate);
            }
        } else if (
            // when workPostcode is ticked
            // and it can't be ticked if it isnt' available due to check input fields being disabled
            postcodeCheckbox.homePostcode        === false &&
            postcodeCheckbox.workPostcode        === true  &&
            postcodeCheckbox.altPostcodeCheckbox === false
        ) {
            setMapCenter(userLocations.work.coordinate);
        } else if (
            postcodeCheckbox.homePostcode        === true &&
            postcodeCheckbox.workPostcode        === false  &&
            postcodeCheckbox.altPostcodeCheckbox === false
        ) {
            setMapCenter(userLocations.home.coordinate);
        }
    
    },[userLocations, postcodeCheckbox])

    const markerIconHandler = (markerType) => {
        let marker = ''
        if(markerType === 'home'){
            marker = homeIcon
        }
        if(markerType === 'work'){
            marker = workIcon
        }
        if(markerType === 'alt'){
            marker = altIcon
        }
        return marker
    }

    const homeIcon = {
        url: home_icon,
        scaledSize: new window.google.maps.Size(30, 30)
    }

    const workIcon = {
        url: briefcase_icon,
        scaledSize: new window.google.maps.Size(30, 30)
    }

    const altIcon = {
        url: location_pin,
        scaledSize: new window.google.maps.Size(30, 30)
    }

    // iconHandler Version-2
    const iconHandler = (IndexAlphabet, Colour) => {
        const iconLink =  `https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${IndexAlphabet}|${Colour}`;
        return iconLink;
    }


    /* Marker InfoWindow states and functions*/ 
    const [infoWindowOpen, setInfoWindowOpen] = useState(false);
    const [selectedMarker, setSelectedMarker] = useState(null);

    const markerClickHandler = (marker) => {
        setSelectedMarker(marker);
        setInfoWindowOpen(true);
    }

    const handleInfoWindowClose = () => {
        setInfoWindowOpen(null);
    }

    // infoWindowOpen isnt visibly used by googlemap api but it is used... 
    // infoWindowOpen state doesn't seem to be used and it will cause linter warning because of this.
    // this use Effect is set to trigger function that returns null to make it acceptable for linter.
    useEffect(()=>{
        const infoWindow = (infoWindowOpen) => {
            if(infoWindowOpen){
                return null
            }
        }
        infoWindow(infoWindowOpen);
    },[infoWindowOpen])

    // Rearranging the searchResultWithMarker in reverse order.
    const searchResultWithMarkerReverseOrder = [...searchResultWithMarker].reverse();
    //const searchResultWithMarkerReverseOrder = [];

    /* the map depth could be 240px less than viewport but we have 
    some padding or margin I can't find. This allows for two rows 
    of legend icons */
    return (
        <div className="onwardReferralMapSizingWrapper" style={{height: 'calc( 100vh - 250px )', width: '100%'}} >
        <GoogleMap
            mapContainerStyle={{ height: '100%', width: '100%' }}
            center={mapCenter} // Placeholder center for initial map load
            zoom={11}
        >
            {userLocationMarkers.map((location, index)=>{
                if(location !== undefined){
                    return (<Marker 
                            key={'marker' + index}
                            position={location.coordinate} 
                            icon={markerIconHandler(location.markerType)}
                        />)
                }
                return null
            })}

            {searchResultWithMarkerReverseOrder.map((clinic, index)=>{
                if(clinic !== undefined){
                    return (
                        <Marker 
                            key={index}
                            position={{lat: clinic.Latitude, lng: clinic.Longitude}} 
                            icon={iconHandler(clinic.IndexAlphabet,clinic.Colour)}
                            onClick={()=>markerClickHandler(index)}
                        >
                            {selectedMarker === index ? <InfoWindow
                                onCloseClick={handleInfoWindowClose}
                                position={{lat: clinic.Latitude, lng: clinic.Longitude}} 
                            >
                                <div className="onwardReferralMapInfoWindow">
                                    <h3>{clinic.FacilityName}</h3>
                                    {clinic.Address1 ? <p>{clinic.Address1}</p> : null}
                                    {clinic.Address2 ? <p>{clinic.Address2}</p> : null}
                                    {clinic.Address3 ? <p>{clinic.Address3}</p> : null}
                                    {clinic.Postcode ? <p>{clinic.Postcode}</p> : null}
                                    <button onClick={(e)=>referralMessageHandler(e, clinic)}>Confirm Your Clinic</button>
                                </div>
                            </InfoWindow> : null}
                            
                        </Marker>
                    )
                }

                return null
            })}
        </GoogleMap>
        </div>
    )

}

export default OnwardReferralMap;