import React, { useCallback, useState, useEffect } from "react";
import ValidatedForm from "./ValidatedForm";
import { OKCancel } from "../util/Buttons";
import Tabs from "../Widgets/Tabs";
import TwoSwitch from "../util/TwoSwitch";
import {validate} from "../Form/FormFunctions/validationFunctions";
import testFieldOrTabIfEvaluatingFalse from "./FormFunctions/testFieldOrTabIfEvaluatingFalse";
import UnValidatedForm from "./UnvalidatedForm";
import getChangeHandler from "./FormFunctions/validationChangeHandler";

const TabbedForm = ({tabsFieldDefinitions, currentFormTab, onTabClick,
    validating, formFieldData,
    setFormFieldData, setDataIsValid, setFormTouched, api,
    createOptionalOverrideFieldComponent, setInvalidFields }) => {
  
    const tabsFieldDefinitionsFiltered = 
        tabsFieldDefinitions.filter(td => testFieldOrTabIfEvaluatingFalse(td, formFieldData));

    const [invalidTabs, setInvalidTabs] = useState([]);

    const tabContent = mapTabFieldDefsToTabNameAndValues(tabsFieldDefinitionsFiltered, invalidTabs, validating);
   
    /* Some code for tab validation removed as it is incomplete. See commit ID 
    a04795068f02f64bda2724d3880a6efe29af1589 (tag: v1.10.6) to restore. */

    const currentTabFieldDefinition = getTabDefinition(tabsFieldDefinitionsFiltered, currentFormTab);
    const formFound = typeof currentTabFieldDefinition === 'object';

    const allTabsFieldDefinitions = 
        getAllFieldsDefsFromTabDefs(tabsFieldDefinitionsFiltered); 

    const objectIndex = 0; // we only use zero currently

    // a length of parms that is an offence to software architecture
    const handleChange = useCallback(getChangeHandler(allTabsFieldDefinitions, formFieldData, setFormFieldData, setDataIsValid, objectIndex, setInvalidFields, setInvalidTabs),
        [formFieldData, setFormFieldData]);


    return <div className="tabbed-form">

        <Tabs content={tabContent} currentTab={currentFormTab} onClick={onTabClick} />

        <TwoSwitch test={formFound} >
            <SingleTab 
                fieldDefinitions={currentTabFieldDefinition}
                formFieldData={formFieldData}
                handleChange={handleChange}
                setDataIsValid={setDataIsValid}
                setFormTouched={setFormTouched}
                api={api}
                createOptionalOverrideFieldComponent={createOptionalOverrideFieldComponent}
                validating={validating}
            />
            <p className='iprs-tabbedform-not-selected' >No Tab Selected</p>
        </TwoSwitch>
    </div>
}

const getANameForATab = tab => tab.text?? tab.name;

/* we inject the name of the the tab so that validation can 
discover which tab a field / question it came from */
const getAllFieldsDefsFromTabDefs = tabsFieldDefinitions => 
    tabsFieldDefinitions.flatMap( tab => tab.Question.map( q => ({...q, __tabname: getANameForATab(tab)}) ) );

/**
 * Maps field definitions into an array of objects 
 * of form { name, value, invalid }
 * @param {Array} tabsFieldDefinitions Array of field definitions 
 * @param {Array} invalidTabs Array of invalid tab names
 * @param {boolean} validating Switch used to turn validation on and off
 * @returns 
 */
const mapTabFieldDefsToTabNameAndValues = (tabsFieldDefinitions, invalidTabs, validating) =>
    tabsFieldDefinitions
        .map(tabDef => {
            const name = tabDef.name ?? tabDef.value ?? tabDef.text;
            const value = tabDef.value ?? tabDef.text;
            const invalid = !validating || invalidTabs.includes(name);
            return { name, value, invalid }
        });

const getTabDefinition = (tabsFieldDefinitions, currentFormTab) => {
    return tabsFieldDefinitions.find( t => currentFormTab === t.name || 
        currentFormTab === t.value || 
        currentFormTab === t.text )?.Question;
}

const SingleTab = ({validating, fieldDefinitions, formFieldData,
    handleChange, setFormTouched, api,
    createOptionalOverrideFieldComponent }) => {

    return <form role='form'>
        <div>
            <UnValidatedForm
                validating={validating}
                fieldDefinitions={fieldDefinitions}
                formFieldData={formFieldData}
                onChange={handleChange}
                setFormTouched={setFormTouched} 
                api={api}
                createOptionalOverrideFieldComponent={createOptionalOverrideFieldComponent} />
        </div>
    </form>
}

export default TabbedForm;
