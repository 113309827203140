import React, { useCallback, useEffect, useState }                      from "react";
import { LoadingIndicator, TwoSwitch }                                
    from "../../lib/iprs-react-library/src/index";

/**
 * React component that can toggle a case value such as onHold
 * @param {*} param0 
 * @returns 
 */
const CaseStatusToggleButton =  ({api, config, setError, error, setLoading, 
    loading, caseID, caseOb, value, setValue}) => {

    const [reviewDate, setReviewDate]       = useState(null);

    const [showReason, setShowReason]       = useState(false);

    const [reason, setReason]               = useState('');

    useEffect(() => {
        setValue(caseOb?.[config.settingName]);
        console.log('updating value', caseOb?.[config.settingName], config.settingName);
    }, [caseOb, config.settingName, setValue]);
            
    const updateStatus = useCallback(
        e => {
            e.preventDefault();

            const newValue = true !== value;
            setLoading(true);

            const params = { 'CaseID': caseID, Value: newValue, Reason: reason };
            if(config.showReviewDate && newValue){ 
                // only send review date if we are putting on hold and not taking off hold
                params.ReviewDate = reviewDate;
            }

            api.transact(config.messageID, params)
            .then(r=>r.apiResult)
            .then(r=>{
                
                setValue(newValue);
                setError(null);
                setLoading(false);
                setShowReason(false);
                setReason('');
                setReviewDate('');
                if(config.updateWorkflows && !newValue){ // taking off hold
                    // just press the workflow back button to refresh the workflow list
                    const workflowBackButton = document.getElementById('CaseWorkflow_WebControlKitGridViewPageFirst'); 
                    if(workflowBackButton) workflowBackButton.click();
                }
            })
            .catch(e=>{
                console.log('Next Load -- ERROR', config.messageID, e);
                setError(e.message);
                setLoading(false);
            });
        }, 
    [api, setLoading, setError, setValue, value, config.messageID, caseID, reason, 
        config.showReviewDate, config.updateWorkflows, reviewDate]);

    const buttonText = (() => {
        if(loading){
            return 'Please wait';
        }else if(!showReason){
            return value? config.offShort: config.onShort;
        }
        else {
            return value? config.offLong: config.onLong;
        }
    })();

    const today = new Date();
    today.setDate(today.getDate()+1);
    const minDate = today.toISOString().substring(0, 10);
    today.setFullYear(today.getFullYear()+1);
    const maxDate = today.toISOString().substring(0, 10);

    return <div>
        <TwoSwitch test={showReason} >
            <div className="OverlayWrapper" >
                <div className='Overlay form' >
                    <form onSubmit={updateStatus} id='updateStatusForm' >
                        <fieldset>
                            <legend>{!value ? config.why : config.whyNot}</legend>
                            <div className='form__field form__field--full-width' >
                                <label htmlFor='reason' className='form__label' >Reason (min 5 letters)</label>
                                <textarea cols={25} rows={1} type='text' id='reason' value={reason} 
                                    onChange={e => setReason(e?.target?.value)} required={true} minLength={5} 
                                    placeholder={'Reason'} ></textarea>
                            </div>
                            
                            <TwoSwitch test={config.showReviewDate && !value}>
                                <div className='form__field' >
                                    <label htmlFor='reviewDate' className='form__label' >Review Date (after today)</label>
                                    <input type='date' id='reviewDate' value={reviewDate}
                                        onChange={e => setReviewDate(e?.target?.value)} required={true} 
                                        min={minDate}
                                        max={maxDate} />
                                </div>
                            </TwoSwitch>
                        </fieldset>
                    </form>
                    <div className="buttonWrapper">
                    <button form="updateStatusForm" className="Button" type="submit"
                        disabled={false&&!(reason?.length > 0)} >
                            <TwoSwitch test={loading} >
                                <LoadingIndicator loading={loading} />
                                <>{buttonText}</>
                            </TwoSwitch></button>
                    <button onClick={e => setShowReason(false)} className="Button" type="button" disabled={false} >Cancel</button>
                    </div>
                </div>
            </div>
            <button onClick={e => setShowReason(true)} className="Button CaseControlButton" type="button" disabled={null === value} >
                <TwoSwitch test={loading} >
                    <LoadingIndicator loading={loading} />
                    <TwoSwitch test={error} >
                        <>Error</>
                        <>{buttonText}</>
                    </TwoSwitch>
                </TwoSwitch>
            </button>
        </TwoSwitch>

        <TwoSwitch test={error} >
            <>
                <h2>Error</h2>
                <p>{error}</p>
            </>
        </TwoSwitch>
    </div>
}

const holdCONFIG = {
    onShort:        'Hold',
    offShort:       'Release',
    onLong:         'Put case on hold',
    offLong:        'Release case from hold',
    messageID:      'CaseOnHold',
    settingName:    'CaseOnHold',
    why:            'Please state why the case should be put on hold and when this will be reviewed',
    whyNot:         'Please state why the case should be taken off hold',
    dateLegend:     '',
    showReviewDate: true,
    updateWorkflows: true       
}

const cancelONFIG = {
    onShort:        'Cancel Treatment',
    offShort:       'Revoke Cancel Treatment',
    onLong:         'Cancel all Treatment',
    offLong:        'Revoke Cancel Treatment',
    messageID:      'CaseCancelled',
    settingName:    'TreatmentCancelled',
    why:            'Please state why all treatment should be cancelled for this case',
    whyNot:         'Please state why a previous cancellation of all treatment should be revoked for this case',
    showReviewDate: false,
    updateWorkflows: false    
}

const CaseHoldButton    = props => React.createElement(CaseStatusToggleButton, {...props, config: holdCONFIG});
const CaseCancelButton  = props => React.createElement(CaseStatusToggleButton, {...props, config: cancelONFIG});

export {CaseHoldButton, CaseCancelButton};