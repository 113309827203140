/**
 * Utility function for eForm
 * Extract the data we need from the URL. The URL structure depends upon
 * whether we are in the pharos or network portal
 * @param {*} getURLPathArray 
 * @param {*} getURLSearchParam 
 * @returns 
 */
const getParamsFromURL = (getURLPathArray, getURLSearchParam) =>{

    let caseid, reportid;
    const urlPath   = getURLPathArray();

    const urlHasInfo = urlPath?.length > 4;
    let formType;
    let isPharos = false;
    if(urlHasInfo){
        isPharos = 'Presentation' === urlPath[1];
        if(isPharos){
            caseid    = getURLSearchParam('CaseID');
            reportid  = getURLSearchParam('ReportID');
            formType  = urlPath[5].toLowerCase().replace('.aspx','');
        }else{
            caseid    =  urlPath[4];
            reportid  =  urlPath[5];
            formType  =  urlPath[2].toLowerCase().replace('-assessment','');
        }
    }
    
    const isInitial = initialRegex.test(formType);

    return { urlHasInfo, isPharos, caseid, reportid, isInitial };
}

const initialRegex = /initial/i;

export default getParamsFromURL;