import React from "react";

import TwoSwitch from '../util/TwoSwitch';

const Tabs = (props) => {

    const {content, currentTab, onClick} = props;

    const errorMessage = validateProps(props); 

    return <>
        <ul className='iprs-tabs' role='tablist' >
            {content?.map((t, i) =>
                <Tab tabDefinition={t} currentTab={currentTab} onClick={onClick} key={i} />)}
        </ul>
        <TwoSwitch test={null !== errorMessage}>
            <div className='iprs-tabs iprs-tabs-invalid'>{errorMessage}</div>
        </TwoSwitch>
    </>
};

const validateProps = props => {
    let errorMessageEnd = '';
    if(!Array.isArray(props.content)){
        errorMessageEnd += "Component needs 'content' (Array) prop";
    }
    if(typeof props.currentTab === 'undefined'){
        errorMessageEnd += "Component needs 'currentTab' prop";
    }
    if(typeof props.onClick !== 'function'){
        errorMessageEnd += "Component needs 'onClick' prop";
    }
    const errorMessage = errorMessageEnd? 
        ('<Tabs>Invalid props: ' + errorMessageEnd + '</tabs>'):
        null;

    return errorMessage;
}

const Tab = ({tabDefinition, currentTab, onClick}) => {

    const {name: tabName, text = null, value = null, invalid} = tabDefinition;
    const tabText = value? value: text;

    const selected  = tabName === currentTab;
    const classes   = 'iprs-tab'            + (selected? ' iprs-tab-selected': '');
    const liClasses = 'iprs-tab-list-item'  + (selected? ' iprs-tab-list-item-selected': '');

    const handleClick = e => {
        e.preventDefault();
        onClick({preventDefault: ()=>null, target: { name: tabName, value: tabText }});
    };

    return <li className={liClasses} >
            <a 
                href='#' 
                aria-selected={selected} 
                className={classes} 
                role='tab' 
                onClick={handleClick}
            >{tabText}
            <TwoSwitch test={invalid} >
                <span className='iprs-tab-list-item-invalid-icon'>!</span>
            </TwoSwitch>
            </a>
        </li>
    };

export default Tabs;