import { useEffect, useState } from "react";

const TemplateSelect = ({isEmail, caseid, reportid, setMessageTxt, setSubject, api, setFrom}) => {
    
    const [templates, setTemplates] = useState([]);
    const [selected, setSelected]   = useState(0);

    const messageID = isEmail? 'UseableEmailList':
        'UseableSMSList';

    const handleChange = e => {
        const selectedValue = e.target.value;
        
        handleSelectedTemplate(api, caseid, reportid, selectedValue);

        setSelected(selectedValue);
    }

    const handleSelectedTemplate = (api, caseid, reportid, emailid) => {
        api.transact('GenerateEmail', { 
            CaseID:     caseid,
            ReportID:   reportid,
            EmailID: emailid
        })
        .then(r => {
            console.log(r)
            return r.apiResult
        })
        .then(r => {
            setMessageTxt(r?.Body);
            setSubject(r?.Subject);
            setFrom(r?.EmailFrom)
        })
        .catch( e => console.log(e) );
    }

    useEffect( () => {
        api.transact(messageID, { 
            CaseID:     caseid, 
            ReportID:   reportid,
            PartyID:    0,
            FacilityID: 0
        })
        .then(r => r.apiResult)
        .then(r => r.map(i => ({
            ...i, 
            value: isEmail? i.pkEmailID:        i.pkSMSID,
            text:  isEmail? i.EmailDescription: i.SMSDescription,
        })))
        .then(r => setTemplates(r))
        .catch( e => console.log(e) );
    }, [api, setTemplates, caseid, reportid, messageID, isEmail]);

    return <div className='form__field form__field--full-width' >
        <label htmlFor='template'
            className="form__label" >{isEmail? 'Email Template': 'SMS Template'}</label>

        <div className="form__dropdown-wrapper ">
            <select className='form__dropdown' id='template' value={selected} onChange={handleChange} >
                <option value={0} key={0} >Select</option>
                {templates.map( i => <option value={i.value} key={i.value} >{i.text}</option>)}
            </select>
        </div>
    </div>
}

export default TemplateSelect;

