import React from "react";
import ReactDOM from "react-dom";
import "../index.css";
import Physitrack from "../components/physitrack/Physitrack";
import Eform from "../components/eform/Eform";
import LoginForm from "../components/login/LoginForm";

import API from "iprs-api-library";
import { getURLPathArray, getURLSearchParam } from "../lib/iprs-react-library/src/index";
import CaseSave from "../components/pharos/CaseSave";
//WR-487 Temporarily removed. Will be added back in a future release.
//import F2FSelfReferral                          from './components/pharos/F2FSelfReferral';
import OnwardReferralForm from "../components/OnwardReferral/OnwardReferralForm";
import CaseStatusButtons from "../components/pharos/CaseStatusButtons";

const weblayerURL = process.env.REACT_APP_IPRS_WEB_API_URL_ALLOW_GLOBAL_OVERRIDE
    ? window?.IPRS_ICORE_GLOBAL?.weblayerURL ??
      process.env.REACT_APP_IPRS_WEB_API_URL
    : process.env.REACT_APP_IPRS_WEB_API_URL;

const prodIndex = () => {
const api = new API(null, weblayerURL, null);
{
    // A bit ugly but pharos stores a token called Token
    // the api stored a session variable called apiToken
    const pharosToken = window.localStorage.getItem("Token");
    if (pharosToken) {
        console.log("pharosToken", pharosToken);
        api.setToken(pharosToken);
    }
}

try {
    const physitrackElem = document.getElementById("physitrack");
    if (null !== physitrackElem) {
        ReactDOM.render(
            <React.StrictMode>
                <Physitrack api={api} />
            </React.StrictMode>,
            physitrackElem
        );
    }
} catch (e) {
    console.log("physitrackElem LOAD ERROR", e);
}

{
    const caseSaveElem = document.getElementById("CaseSaveDiv");
    if (null !== caseSaveElem) {
        //api.setAlternateNetworkConnectionFunction(mockNetwork);
        ReactDOM.render(
            <React.StrictMode>
                <CaseSave api={api} />
            </React.StrictMode>,
            caseSaveElem
        );
    }
}

{
    const onholdButtonsDiv = document.getElementById("onhold-buttons-div");
    if (null !== onholdButtonsDiv) {
        //api.setAlternateNetworkConnectionFunction(mockNetwork);
        ReactDOM.render(
            <React.StrictMode>
                <div>
                    <CaseStatusButtons api={api} />
                    <CaseSave api={api} />
                    {/* WR-487 Temporarily removed. Will be added back in a future release.
                    <F2FSelfReferral  api={api} /> */}
                </div>
            </React.StrictMode>,
            onholdButtonsDiv
        );
    }
}

{
    const onholdButtonsDiv = document.getElementById("post-save-div");
    if (null !== onholdButtonsDiv) {
        //api.setAlternateNetworkConnectionFunction(mockNetwork);
        ReactDOM.render(
            <React.StrictMode>
                <div>
                    <CaseSave api={api} />
                </div>
            </React.StrictMode>,
            onholdButtonsDiv
        );
    }
}

try {
    /* Eforms are only loaded for non-MSK assessments 
    For portal the element called eform with class caseType-PSYCH is added by the proxy.
    */
    const pharosAssesmentPageRegex      = new RegExp('^/Presentation/Page/PMS/eForm/(Initial|FollowUp).aspx');
    const isPharosAssesmentURL          = pharosAssesmentPageRegex.test(window.location.pathname);
    const isMSKinURL                    = 'msk'     === window.location.search?.caseType?.toLowerCase();
    const isMSKinDoc = doc => {
        const caseTypeHiddenField = doc.getElementById('CaseTypeHiddenField');
        const caseType = caseTypeHiddenField?.value;
        return '2' !== caseType; // MSK is type 1
    }

    const portalContentEl = document.getElementById("eform");
    const isPortalElPsyc = portalContentEl && portalContentEl.classList.contains("caseType-PSYCH");

    const insertForm                    = isPortalElPsyc || 
        (isPharosAssesmentURL && !(isMSKinURL || isMSKinDoc(window.document)));

    if (insertForm) {

        const pharosContentEl = document.getElementById("ApplicationContent");
        const eFormInsertElem = portalContentEl?? pharosContentEl;

        console.log("eFormInsertElem", eFormInsertElem);

        if(eFormInsertElem){
            eFormInsertElem.innerHTML = "";
            ReactDOM.render(
                <React.StrictMode>
                    <Eform
                        api={api}
                        getURLPathArray={getURLPathArray}
                        getURLSearchParam={getURLSearchParam}
                    />
                </React.StrictMode>,
                eFormInsertElem
            );
        }
    }
} catch (e) {
    console.log("EFORM LOAD ERROR", e);
}

// Login
const LoginElem = document.getElementById("loginForm");
if (null != LoginElem) {
    /* these used to be done by the proxy. Beware that 
    the react code replaces the form so you won't see
    these elements in the DOM at run time */
    const securityIdEl  = document.getElementById('MemberLoginForm_LoginForm_SecurityID');
    const backUrlEl     = document.getElementById('MemberLoginForm_LoginForm_BackURL');
    const getValue = el => el?.getAttribute('value');
    const securityID = getValue( securityIdEl );
    const backURL = getValue( backUrlEl );
    ReactDOM.render(
        <React.StrictMode>
            <LoginForm securityID={securityID} backURL={backURL} api={api} />
        </React.StrictMode>,
        LoginElem
    );
}

// Onward Referral Form
const OnwardReferralElem = document.getElementById("onwardReferral");
if (null != OnwardReferralElem) {
    ReactDOM.render(
        <React.StrictMode>
            <OnwardReferralForm
                api={api}
                getURLSearchParam={getURLSearchParam}
            />
        </React.StrictMode>,
        OnwardReferralElem
    );
}
}

export default prodIndex;

