import React, {useCallback} from "react"
import phoneIcon from "./img/new/phone-icon.svg";
import envelope from "./img/new/envelope-icon.svg";
import homeIcon from "./img/new/home-icon.svg";
import briefCaseIcon from './img/new/briefcase-icon.svg';
import locationIcon from "./img/new/location-pin-icon.svg";
import clinicIcon from "./img/new/medical-briefcase-icon.svg";
import gbpIcon from "./img/new/gbp-icon.svg";
import clockIcon from "./img/new/clock-icon.svg";
import parkingIcon from "./img/new/parking-signs-icon.svg";
import wheelChairIcon from "./img/new/wheelchair-icon.svg";

const ClinicList = ({backToSearch, 
                     searchResultWithMarker,
                     referralMessageHandler}) => {
    
    // const searchResultWithMarker = [];

    // Function to handle opening hours string and returns an array from the given opening hours string for each clinics.
    // * strings doesn't have a set standard at the moment and making a regular expression for all the different cases
    // seems to be impossible but this regexs still works for some of them.
    const openingHoursHandler = (inputString, facilityName) => {

        // Regex to filter out OPM clinics to bypass regex process to break down the opening hours for text formatting (putting breaklines for readability)
        const opmRegex = /^OPM/;

        // Empty array to store
        let matches = [];

        console.log(inputString)

        if(opmRegex.test(facilityName)){
            return [inputString]
        } else if(inputString === null) {
            // To prevent error, if opening hour is set to null, this function will return an empty array.
            return []
        } else {
            const regex1 = /(?:[A-Za-z]+(?:\s?-\s?[A-Za-z]+)*?\s\d{1,2}(?::\d{2})?(?::\d{2})?(?:\s?[ap]m)?\s?-\s?\d{1,2}(?::\d{2})?(?::\d{2})?(?:\s?[ap]m)?)/g;
            const regex2 = /(?:[A-Za-z]+(?:,?\s?and\s)?(?:-?\s?[A-Za-z]+)*?\s\d{1,2}(?::\d{2})?(?:\s?[ap]m)?(?:\s?-\s?\d{1,2}(?::\d{2})?(?::\d{2})?(?:\s?[ap]m)?)?)/g;
            const regex3 = /(?:[A-Za-z]+(?:\s?-\s?[A-Za-z]+)*?\s\(\d{1,2}(?::\d{2})?(?:\s?[ap]m)?\s?to\s\d{1,2}(?::\d{2})?(?::\d{2})?(?:\s?[ap]m)?\))/g;
            const regex4 = /(?:[A-Za-z]+(?:\s?to\s?[A-Za-z]+)?)/g;
            const regex5 = /(?:[A-Za-z]+(?:\s?-\s?[A-Za-z]+)*?\s\d{1,2}(?::\d{2})?(?:\.\d{1,2})?(?:\s?[ap]m)?\s?-\s?\d{1,2}(?::\d{2})?(?:\.\d{1,2})?(?:\s?[ap]m)?)/g;

            let match;

            while ((match = regex1.exec(inputString)) !== null) {
              matches.push(match[0]);
            }
          
            if (matches.length === 0) {
              const timeSlots = inputString.split(',');
              for (const slot of timeSlots) {
                const trimmedSlot = slot.trim();
                regex2.lastIndex = 0; // Reset lastIndex before each iteration
                if ((match = regex2.exec(trimmedSlot)) !== null) {
                  matches.push(match[0]);
                }
              }
            }
          
            if (matches.length === 0) {
              if ((match = regex3.exec(inputString)) !== null) {
                matches.push(match[0]);
              }
            }
          
            if (matches.length === 0) {
              if ((match = regex4.exec(inputString)) !== null) {
                matches.push(match[0]);
              }
            }
          
            if (matches.length === 0) {
              if ((match = regex5.exec(inputString)) !== null) {
                matches.push(match[0]);
              }
            }
          
            return matches;
        } 

    };

    const clinicListHandler = useCallback(() => {

        // List of clinic card will be generated here and stored in searchResultList.
        const searchResultList = searchResultWithMarker.map((r, index)=>{

            const priority = r.Priority ?? 0;
            // Each clinic card will be generated in this .map() function
            return(
                <div className="clinicCard" style={{border: `3px solid #${r.Colour}`}} key={index + 'clinicItem'}>
                    {/* Heading of the card (Pin and Clinic name) */}
                    <div>
                        <div className="clinicCardRow">
                            <div className="clinicCardRowClinicName">
                                <img
                                    src={`https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${r.IndexAlphabet}|${r.Colour}`}
                                    className="legendIcons"
                                    alt={r.FacilityName}
                                />
                                {priority === 0 ? 
                                    <h3 className="clinicListCardClinicName"> {r.FacilityName}</h3>:
                                    <h3 className="clinicListCardClinicName"> {r.Priority}  {r.FacilityName}</h3>}
                            </div>
                        </div>
                    </div>

                    <hr />

                    {/* Clinic address and Distance between home or work to the clinic */}
                    <div className="clinicAddressAndDistance">
                        <div className="clinicAddress">
                            <img src={clinicIcon} alt="clinicIcon" className="clinicIcon" title="Clinic Address"/>
                            <p>
                                {r.Address1 !== null ? r.Address1 : ("")} <br />
                                {r.Address2 !== null ? r.Address2 : ("")} {r.Address2 !== null ? <br /> : ("")}
                                {r.Address3 !== null ? r.Address3 : ("")} {r.Address3 !== null ? <br /> : ("")}
                                {r.Postcode} <br />
                                {r.WebAddress !== null ? r.WebAddress : ("")} {r.Country !== null ? <br /> : ("")}
                            </p>
                        </div>
                        <div className="homeAndWorkDistanceAndTime">
                            <div>
                                {r.From === 'Home' ? <img src={homeIcon} width="18px" height="20px" alt="phoneIcon"/> :  <></>}
                                {r.From === 'Work' ? <img src={briefCaseIcon} width="18px" height="20px" alt="envelopeIcon"/> :  <></>}
                                {r.From === 'Alternative' ? <img src={locationIcon} width="18px" height="20px" alt="locationIcon"/> :  <></>}
                                <div>
                                    {`${r.GoogleDistance?.toFixed(2)} miles`} <br/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Email and Phone number */}
                    <div className="emailAndPhone">
                        {r.TelephoneNumber !== undefined && r.TelephoneNumber !== null ? (
                            <div>
                                <img src={phoneIcon} width="18px" height="20px" alt="phoneIcon" title="Telephone"/>
                                <div>{r.TelephoneNumber}</div>
                            </div>
                        ) : (
                            <></>
                        )}
                        {r.ElectronicAddress !== undefined && r.ElectronicAddress !== null ? (
                            <div>
                                <img src={envelope} width="18px" height="20px" alt="envelopeIcon" title="Email"/>
                                <div>{r.ElectronicAddress}</div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>

                    {/* Clinic Fees */}
                    {r.Fees.length !== 0 && r.Fees.length !== 0 ? (
                        <div className="clinicFees">
                            <img src={gbpIcon} alt="Clinic Fees" title="Clinic Fees"/>
                            <table>
                                <tbody>
                                    {r.Fees !== undefined ? (
                                        <>
                                            {r.Fees.map((fee, index)=>{
                                                return <tr key={"fee.fkFacilityId" + index}>
                                                    <td valign="top">
                                                        {fee.ServiceChargeDescription} <br/>
                                                        {fee.OrganisationName !== null ? '('+fee.OrganisationName+')' : ''}
                                                    </td>
                                                    <td>
                                                        <span style={{marginLeft: "10px"}}>£{fee.Amount}</span>
                                                    </td>
                                                </tr>
                                            })}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    ) : (<></>)}

                    {/* Clinic Opening Hours */}
                    <div className="openingHoursTable">
                        <label>
                            <img className="openingTimeTitle" src={clockIcon} alt="Opening hours" title="Opening hours" />
                            {/* <p>
                                {r.OpeningHours}
                            </p> */}
                             <tbody className="openingTimeContent">
                                {openingHoursHandler(r.OpeningHours, r.FacilityName).map((time)=>{
                                   return <tr>
                                       <td>{time}</td>
                                   </tr>
                                })}
                            </tbody>
                        </label>
                    </div>

                    {/* Disability Access and Car Parking */}
                    <div className="disabilityAccessAndCarParking">
                        <div>
                            <label>
                                <div className="labelDivName">
                                    <img className="openingTimeTitle" src={wheelChairIcon} alt="Disability Access" title="Disability Access" />
                                </div>
                                <div className="labelDivContent">
                                    {r.DDACompliant === 'Yes' ? <span>&#10003;</span> : <span>&#10006;</span>}
                                </div>
                            </label>
                        </div>
                        <div>
                            <label>
                                <div className="labelDivName">
                                    <img className="openingTimeTitle" src={parkingIcon} alt="Parking" title="Parking" />
                                </div>   
                                <div className="labelDivContent">
                                    {r.CarParking === 'Yes' ? <span>&#10003;</span> : <span>&#10006;</span>}  
                                </div>
                            </label>
                        </div>
                    </div>

                    {/* Clinic Web Address */}
                    {r.WebAddress !== '' ? (
                        <div className="onwardReferralWebAddress">
                            <label>
                                <div className="onwardReferralWebAddressTitle">
                                    Web Address: 
                                </div>
                                <div className="onwardReferralWebAddressContent">
                                    <a target="_blank" className="onwardReferralWebAddressAnchor" href={r.WebAddress} rel="noreferrer">{r.WebAddress}</a>
                                </div>
                            </label>
                        </div>
                    ) : (<></>)}

                    <hr />

                    {/* Make Referral Button (This will open up the Referral Message Modal) */}
                    <div className="makeReferralButton">
                        <button onClick={(e)=>referralMessageHandler(e, r)}>Make Referral</button>
                    </div>
                </div>
            )
        })

        return (
            <>
                {searchResultList}
            </>
        )
    },[searchResultWithMarker, referralMessageHandler])

    return (
        <div className="clinicList">
            <button 
                onClick={backToSearch}
                className="onwardReferralBackToSearchButton"
            >
                    {'<<'} Back to search.
            </button>
            <h2>Clinic List</h2>

            {/* clinicList version 2 */}
            {clinicListHandler()}
        </div>
    )
}

export default ClinicList