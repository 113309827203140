import React, { useEffect, useState }   from 'react';
import { CKEditor }                     from '@ckeditor/ckeditor5-react';
import ClassicEditor                    from '@ckeditor/ckeditor5-build-classic';

const TextEditor = ({data, setMessageToSend, id}) => {

    const [message, setMessage] = useState();

    const handleOnChange = (message) => {
        setMessage(message);
    }

    useEffect(()=>{
        setMessageToSend(message)
    },[message, setMessageToSend]);

    return (
        <div >
            <CKEditor
                id={id}
                editor={ ClassicEditor }
                data={data}
                onReady={ editor => {
                    console.log( 'Editor is ready to use!', editor );
                } }
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    handleOnChange(data)
                } }
                onBlur={ ( event, editor ) => {
                    console.log( 'Blur.', editor );
                } }
                onFocus={ ( event, editor ) => {
                    console.log( 'Focus.', editor );
                } }
            />
        </div>
    )
}

export default TextEditor