import padIntStart from '../util/PadIntStart';

const getTimeStr = date => {

    let dateToConvert;
    if( !(date instanceof Date)){
        dateToConvert = new Date(Date.parse(date));
    } else {
        dateToConvert = date;
    }

    return  padIntStart( dateToConvert.getHours(), 2 ) + ':' +
            padIntStart( dateToConvert.getMinutes(), 2);
}

const getDateISOWithoutTime = date => {

    let dateToConvert;
    if( !(date instanceof Date)){
        dateToConvert = new Date(Date.parse(date));
    } else {
        dateToConvert = date;
    }

    const result =
        padIntStart(dateToConvert.getFullYear(), 4) + '-' +
        padIntStart(dateToConvert.getMonth() + 1, 2 ) + '-' +
        padIntStart(dateToConvert.getDate(), 2);
    return result;
}

const getDateTimeStr = ( date ) => {
    
    let dateToConvert;
    if( !(date instanceof Date)){
        dateToConvert = new Date(Date.parse(date));
    } else {
        dateToConvert = date;
    }
    
    return getDateISOWithoutTime(dateToConvert) + 'T' +
           getTimeStr( dateToConvert )
}

const getShortDOW = date => {
    
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 
        'Fri', 'Sat' ];
    
    return days[ date.getDay() ];
}

const getDateStr = ( date ) => {
    const thisYear = new Date().getFullYear();
    const yearSt = thisYear === date.getFullYear()? '': ( ' ' + date.getFullYear());
    return '' + getShortDOW( date ) + ' ' +  date.getDate() + '/' + ( date.getMonth() + 1 ) + yearSt;      
}

const inPast = date =>  {
    let result = false;
    if( date instanceof Date ){
        result = date.valueOf() < Date.now();
    }else{
        result = new Date( date ).valueOf() < Date.now();
    }
    
    return result;
}

const parseDate = dateSt => {
    return new Date(dateSt);
}

class TimePeriod {
    #start; 
    #end;
    constructor( start, end ){
        
        const st = start instanceof Date? start: parseDate( start );
        const ed = end   instanceof Date? end:   parseDate( end );
        
        this.#start  = st;
        this.#end    = ed;
    }
    
    get start() {return this.#start};
    get end()   {return this.#end};
    
    overlaps( timePeriod ){
        if( !(timePeriod instanceof TimePeriod) ){
            throw new Error("Incompatible timer period in overlaps function");
        }
        
        const result = 
            ( (!this.adjoins( timePeriod ) )
            && (
            ( (this.start.valueOf() >= timePeriod.start.valueOf()) && (this.start.valueOf() <= timePeriod.end.valueOf()))
            ||
            ((timePeriod.start.valueOf() >= this.start.valueOf()) && (timePeriod.start.valueOf() <= this.end.valueOf()) ) ) );
    
        return result;
    }
    
    adjoins( timePeriod ){
        if( !(timePeriod instanceof TimePeriod) ){
            throw new Error("Incompatible timer period in overlaps function");
        }
        
        const result =  
            (((this.start.valueOf() === timePeriod.end.valueOf())
            ||
            (this.end.valueOf()     === timePeriod.start.valueOf())));
    
        return result;
    }
}

/* Calculate the beginning of the current week and the date 1 or more weeks 
 * after*/
const getWeekStartEnd = (date, weeks = 1) => {
    
    const dow   =       date.getDay()
    const year  =       date.getFullYear()
    const month =       date.getMonth()
    const dom   =       date.getDate()
    
    const dayAdjust =   dow > 6? 7: dow - 1
    
    // these are int values from setDate
    const weekStart =   new Date( year, month, dom )
            .setDate( dom - dayAdjust )
    const weekEnd   =   new Date( year, month, dom, 23, 59, 59 )
            .setDate( dom - 1 + ( 7 * weeks ) - dayAdjust )
    
    /* setDate does wrapping so will go into previous month if necessary.
     * So, if the current date is Tuesday 1 June, then the first value returned 
     * will be 31 May. The second value should be one second less than 7 days
     * after the first value, so as to be clear that we end on the Sunday */
    return [ new Date( weekStart ),
             new Date( weekEnd   ) ]
}

const isISODate = (dateString) => {
    // parse with regex
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return (regex.test(dateString));
}

export {getWeekStartEnd, inPast, getDateTimeStr, TimePeriod, parseDate, getDateStr,
    getShortDOW, getDateISOWithoutTime, isISODate}
export default getTimeStr
