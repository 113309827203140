import React, {useEffect, useState} from 'react'

const FacilityName = ({api, facilityName, setFacilityName}) => {
    const [clinicList, setClinicList] = useState([])
    
    const facilityNameHandler = (e) => {
        setFacilityName(e.target.value);
    }

    const clinicListHandler = (clinicNamesInApiResult) => {
        let clinicNames = []
        if(clinicNamesInApiResult !== 0){
            clinicNames = clinicNamesInApiResult.map((clinic, index)=>{
                console.log(clinic.FacilityName)
                return <option key={clinic + index} value={clinic.FacilityName}>{clinic.FacilityName}</option>
            })
        }
        return clinicNames
    }

    useEffect(()=>{
        if(facilityName.length >= 2){

            const params = {
                "FacilityName": facilityName
            }

            api.transact("GetPractices", params)
            .then(r=>{
                setClinicList(clinicListHandler(r.apiResult))
            }) 
            .catch(e=>{
                console.log(e)
            })
        }
    },[facilityName, api])

    return (
        <>
            <label>
                <div>
                    <div>
                        Clinic Name
                    </div>
                    <div >
                        <input 
                            className="SearchTextInputFullSize" 
                            type="text"
                            list="facilityNameList"
                            value={facilityName}
                            onChange={facilityNameHandler}
                        />
                        <datalist id="facilityNameList">
                            {clinicList.map(r=>{
                                return r
                            })}
                        </datalist>
                    </div>
                </div>
            </label>
        </>
    )
}

export default FacilityName