import React, { useRef, useState }  from 'react';
import { useCallback }      from 'react';

const LoginForm = ({securityID, backURL, api}) => {

    const [message, setMessage]     = useState('');

    const [username, setUsername]    = useState('');
    const [password, setPassword]    = useState('');

    const [loggedIn, setLoggedIn]    = useState(false);

    const formEl                     = useRef();

    const handleSubmit = useCallback(e => {
        if (!loggedIn) {
            e.preventDefault();
            if (0 === username.length || 0 === password.length) {
                setMessage('You must enter a username and a password');
            } else {
                api.login(username, password)
                    .then(r => {
                        setLoggedIn(true);
                        setMessage('Reloading');
                        // acually submit now
                        formEl?.current.submit();
                    })
                    .catch(err => {
                        setMessage(err.message);
                    })
            }
        }
    }, [username, password, loggedIn, api]);

    return <div className="security">
        <div className="typography">
            <h1>Log in</h1>
            <form id="MemberLoginForm_LoginForm" action="/Security/login/default/LoginForm/" method="post"
                encType="application/x-www-form-urlencoded" ref={formEl} > 
                {message ? <p id="MemberLoginForm_LoginForm_error" className="message bad" >{message}</p> : null}
                <fieldset>
                    <div className="fieldset">
                        <input type="hidden" name="AuthenticationMethod" value="App\Security\MemberAuthenticator"
                            className="hidden" id="MemberLoginForm_LoginForm_AuthenticationMethod" />
                        <div className="form-group text" id="MemberLoginForm_LoginForm_Email_Holder">
                            <div className="form-group__field">
                                <label for="MemberLoginForm_LoginForm_Email"
                                    className="form__field-label form__field-label--required">Email</label>
                                <div className="form__field-holder">
                                    <input type="text" name="Email" className="text" id="MemberLoginForm_LoginForm_Email"
                                        autofocus="true" required="required" 
                                        aria-required="true" value={username} onChange={e=>setUsername(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group text password" id="MemberLoginForm_LoginForm_Password_Holder">
                            <div className="form-group__field">
                                <label for="MemberLoginForm_LoginForm_Password"
                                    className="form__field-label form__field-label--required">Password</label>
                                <div className="form__field-holder">
                                    <input type="password" name="Password" className="text password"
                                        id="MemberLoginForm_LoginForm_Password" required="required" aria-required="true"
                                        autocomplete="off" value={password} onChange={e=>setPassword(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div id="MemberLoginForm_LoginForm_Remember_Holder" className="form-group field checkbox">
                            <div className="form__field-holder form-check">
                                <label className="form-check-label">
                                    <input type="checkbox" name="Remember" value="1" className="checkbox"
                                        id="MemberLoginForm_LoginForm_Remember"
                                        title="Remember me next time? (for 90 days on this device)" />
                                    <span>Keep me signed in</span>
                                </label>
                            </div>
                        </div>
                        <input type="hidden" name="BackURL" value={backURL} className="hidden"
                            id="MemberLoginForm_LoginForm_BackURL" />
                        <input type="hidden" name="SecurityID" value={securityID}
                            className="hidden" id="MemberLoginForm_LoginForm_SecurityID" />
                    </div>
                </fieldset>
                <div className="form-actions">
                    <input type="submit" name="action_doLogin" value="Log in" className="action"
                        id="MemberLoginForm_LoginForm_action_doLogin" onClick={handleSubmit} />
                    <p id="ForgotPassword"><a href="/Security/lostpassword">I've lost my password</a></p>
                </div>
            </form>
            <p>&nbsp;</p>
            <div className="alert good">
                <p><strong>Authorised Access</strong></p>
                <p>You should only access this site using your authorised, unique login details.</p>
                If do not have login details to access this site, then please contact the supply chain at 
                <a href="mailto:supply.chain@iprsgroup.com">supply.chain@iprsgroup.com</a> or on 
                    <a href="." title="Call +448000721227 via 3CX" tcxhref="+448000721227" target="_blank">0800 072 1227</a> and
                press option 3.
            </div>
        </div>
    </div>
}

export default LoginForm;