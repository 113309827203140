import { TwoSwitch }        from '../../../lib/iprs-react-library/src/index';
import { useState, useEffect }         from 'react';
import Checkboxes           from './Checkboxes';
import TemplateSelect       from './TemplateSelect';
import TextEditor from './TextEditor';

const TYPE_EMAIL    = 'E';
const TYPE_SMS      = 'S';

const CorrespondenceForm = ({ caseid, reportid, api }) => {

    const [type, setType]               = useState(TYPE_EMAIL);

    const [emailList, setEmailList]     = useState([]);
    const [phoneList, setPhoneList]     = useState([]);

    const [messageTxt, setMessageTxt]   = useState('Hello');
    const [subject,    setSubject]      = useState('');
    const [from, setFrom] = useState('');

    const [messageToSend, setMessageToSend] = useState('');

    const [otherRecipient, setOtherRecipient] = useState([]);

    const [recipientList, setRecipientList] = useState([]);
    const [otherRecipientListObj, setOtherRecipientListObj] = useState([]);

    useState(() => loadEmailList(api, caseid, setEmailList),  
        [api, caseid, setEmailList]);

    useState(() => loadSMSList(api, caseid, setPhoneList),    
        [api, caseid, setPhoneList]);

    const isEmail = TYPE_EMAIL === type;

    const [status, setStatus] = useState('Status goes here...');

    const sendCorrespondence = () => {
        const typeId = type === 'E' ? 2001 : 2004;

        console.log(recipientList)

        if(recipientList.length < 1){
            setStatus('No correspondence selected')
        } else {
            api.transact('QueueCorrespondence', { 
                Data:{
                    "CaseID": caseid,
                    "RecipientList": [...recipientList, ...otherRecipientListObj],
                    "Body": messageToSend,
                    "Subject": subject,
                    "From": from,
                    "CorrespondenceTypeID": typeId
                }
            })
            .then(r => {
                if(r.Status === "OK" || r.apiStatus === "OK"){
                    setStatus("Emails successfully queued")
                }
            })
            .catch( 
                e =>{ 
                    setStatus(e.apiText)
                }
            );
        }
    }

    // This useEffect will update the recipientList whenever dependent lists are updated.
    useEffect(()=>{

        let newRecipientList = [];

        if(type === 'E'){
            newRecipientList = [];
            emailList.map(e=>{
                if(e.selected === true){
                    newRecipientList.push({"ElectronicAddressString": e.ElectronicAddressString})
                }
                return null;
            })
        }

        if(type === 'S'){
            newRecipientList = [];
            phoneList.map(p=>{
                if(p.selected === true){
                    newRecipientList.push({"ElectronicAddressString": p.ElectronicAddressString})
                }
                return null;
            })
        }

        setRecipientList([...newRecipientList])

    },[emailList, phoneList, type]);

    useEffect(()=>{
        let newOtherRecipient = [];
        otherRecipient.map(i =>{
            newOtherRecipient.push({"ElectronicAddressString": i})
            return null;
        });

        setOtherRecipientListObj(newOtherRecipient);

    },[otherRecipient])

    const otherHandler = (e) => {
        const otherRecipientValueString = e.target.value

        const otherRecipientArray = otherRecipientValueString.split(";");
        setOtherRecipient(otherRecipientArray);
    }

    return <div className='iprs-eform-correspondence-form' >

        {getSMSEmailSelectHTML(type, setType)}

        <TemplateSelect caseid={caseid} reportid={reportid} setFrom={setFrom}
            api={api} isEmail={isEmail} setMessageTxt={setMessageTxt} setSubject={setSubject} />

        <TwoSwitch test={isEmail} >
            <Checkboxes items={emailList} setItems={setEmailList} />
            <Checkboxes items={phoneList} setItems={setPhoneList} />
        </TwoSwitch>

        {/* <div className="InputLine">
            <div className="InlineBlock">
                Other (please seperate with semi-colon)
            </div>
            <input type="text" className="InlineNoBlock" onChange={e=>otherHandler(e)}  />
        </div> */}

        <div className='form__field form__field--full-width' >
            <label htmlFor='subject' className="form__label" >
                Other (please seperate with semi-colon)
            </label>
            <input type="text" onChange={e=>otherHandler(e)}  />
        </div>

        <TwoSwitch test={isEmail} >
            <div className='form__field form__field--full-width' >
                <label htmlFor='subject' className="form__label" >Subject</label>

                <input id='subject' value={subject} onChange={s=>setSubject(s.target.value)} />
            </div>
        </TwoSwitch>


        <input type="button" className="Button" value="Send" onClick={()=>sendCorrespondence()} />

        <p>{status}</p>

        <div className='form__field form__field--full-width' >

            <label htmlFor='messageText'
                className="form__label" >Message</label>
            
            <TextEditor id='messageText' data={messageTxt} setMessageToSend={setMessageToSend} />
        </div>

    </div>
};

const loadEmailList = (api, caseid, setEmailList) =>{
    api.transact('CaseRelatedElectronicAddressList', {caseid})
        .then( r => r.apiResult )
        .then( r => r.map(i => ({ ...i, name: i.Name,
                                select: false })))
        .then( r => setEmailList( r ) )
        .catch( e => console.log(e) );
}

const loadSMSList = (api, caseid, setPhoneList) => {

    api.transact('CaseRelatedMobileNumberList', { caseid })
        .then(r => r.apiResult)
        .then(r => r.map(i => ({
            ...i, name: i.Name,
            select: false
        })))
        .then(r => setPhoneList(r))
        .catch( e => console.log(e) );
}

const getSMSEmailSelectHTML = (type, setType) => (
    <div className='form__field form__field--full-width' >
        <label htmlFor='correspondence-type'
            className="form__label" >Message Type</label>

        <div className="form__dropdown-wrapper ">
            <select id='correspondence-type' className={'form__dropdown'} value={type} onChange={e => setType(e.target.value)} >
                <option value={TYPE_EMAIL}  >Email</option>
                <option value={TYPE_SMS}    >SMS</option>
            </select>
        </div>
    </div>);



export default CorrespondenceForm;