import { PersonAdd, TwoSwitch }         from '../../lib/iprs-react-library/src/index';
import { useCallback, useEffect, useState }          from 'react';

const Physitrack = ({api}) => {

    const caseID = document.getElementById("CaseIDHiddenField")?.value;

    const [diaryUsers, setDiaryUsers]           = useState(null);

    const [selectedUser, setSelectedUser] = useState(0);

    const [error, setError] = useState('');

    const [setupClinicianIDs, setSetupClinicianIDs] = useState([]);

    const setupClinicians = diaryUsers?.filter(u => setupClinicianIDs?.includes(u.PartyID) );

    const handleUserChange = e => setSelectedUser(Number.parseInt(e.target.value, 10));

    const loadSetupClinicianIDs = useCallback(() => {

        api.transact('ListSettupClinicians', { CaseID: caseID })
            .then(r => r.apiResult)
            .then(r => {
                setSetupClinicianIDs(r);
                setError('');
            })
            .catch(e => setError(e.message));
    }, [api, caseID]);

    useEffect(() => {
        
        api.transact('DiaryUserList', { IsVisual: true})
            .then(r => r.apiResult)
            .then(r => {
                setDiaryUsers(r);
                setError('');
                if(r.length){
                    setSelectedUser(r[0].PartyID);
                }
            })
            .then(r => loadSetupClinicianIDs())
            .catch(e => setError(e.apiText));
    }, [api, loadSetupClinicianIDs])

    const handlAddClick = useCallback(e => {
        e.preventDefault();
        const setupClinician_ = clinicianID => {

            api.transact('SettupPatientForPhysitrack', { CaseID: caseID, ClinicianPartyID: clinicianID })
                .then(r => {
                    loadSetupClinicianIDs();
                    if (!setupClinicianIDs.includes(clinicianID)) {
                        const newDiaryUsers = diaryUsers.filter(r => !setupClinicianIDs.includes(r.PartyID));
                        if (newDiaryUsers.length) {
                            setSelectedUser(newDiaryUsers[0].PartyID);
                        } else {
                            setSelectedUser(0);
                        }
                    }
                    setError('');
                })
                .catch(e => setError(e.apiText));
        }
        setupClinician_(selectedUser);
    }, [api, caseID, diaryUsers, loadSetupClinicianIDs, selectedUser, setupClinicianIDs]);
    
    return (
        <div className="App">

            <h1>Physitrack Setup</h1>
            <p>These clinicians have the patient setup in their Physitrack 
            account</p>
            <p>You can setup the patient in another clinician's Physitrack 
            account below. However, please do not
            setup the patient for clinicians who won't be dealing with the 
            patient's case:-</p>
            {
                <TwoSwitch test={setupClinicians?.length} >
                    <ul style={{listStyle: 'none', fontWeight: 'bold'}}>{setupClinicians && setupClinicians.map(c => <li key={c.PartyID} >{c.PartyName}</li> ) }</ul>
                    <p><b>Nobody is set up</b></p>
                </TwoSwitch>
            }

            <form>
                
                <fieldset style={{display: 'inline-block', border: 0}}>
                    <legend style={{fontWeight: 'bold', paddingTop: '1em'}}>Add another person</legend>
                    <div style={{float: 'left'}}>
                        <label htmlFor='person-select'  >Choose someone to setup</label>
                        <select value={selectedUser} onChange={handleUserChange} name='person-select' id='person-select' >
                            {diaryUsers && diaryUsers.filter(r => !setupClinicianIDs.includes(r.PartyID)).map(r => <option key={r.PartyID} id={'user-' + r.PartyID} value={r.PartyID} >{r.PartyName}</option>)}
                        </select>
                    </div>

                    <div style={{float: 'left'}} >
                        {diaryUsers && <PersonAdd  name='person-add' value='person-add' onClick={handlAddClick} />}
                    </div>
                </fieldset>
            </form>

            {error && <p>Error: {error}</p>}
        </div>
    );
}

export default Physitrack;
