
/**
 * get the case ID if it is either stored in session storage or in 
 * CaseIDHiddenField. 
 * @returns an integer caseID
 */
const getAndSaveCaseID = () => {

    const key = 'reactPostSaveCaseCaseID';
    const caseID = window?.document?.getElementById('CaseIDHiddenField')?.value;

    let result;
    if('undefined' !== typeof caseID){
        window?.sessionStorage?.setItem?.(key, caseID);
        result = caseID;
    }else{
        result =  window?.sessionStorage?.getItem?.(key);
    }
    return Number.parseInt(result, 10);
}

export default getAndSaveCaseID;