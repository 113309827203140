import { LoadingIndicator, TwoSwitch } from "../../lib/iprs-react-library/src/index";
import { useEffect, useState } from "react";
import getAndSaveCaseID from "./getAndSaveCaseID";

/**
 * React component to call a PostSaveCase endpoint after a case has been
 * saved or the patient's addresses saved. This might load on the main
 * case page or in the address update pages. 
 * The address update pages don't contain the caseID, so we store it in 
 * session storage on the case page
 * @param {*} param0 
 * @returns 
 */
const CaseSave = ({api}) => {

    const [message, setMessage]    = useState(null);
    const [loading, setLoading]    = useState(false);

    useEffect(()=>{

        // side effect of storing the caseID
        const caseID = getAndSaveCaseID();

        // looked for the update successful message at top of page
        const statusText = window?.document?.getElementById('StatusLabel')?.textContent;
        const isSave     = /successful/gi.test(statusText);

        // only do the transaction if there has been a save
        if(isSave){
            if(Number.isNaN(caseID) || caseID < 1 || caseID > Number.MAX_VALUE){
                setMessage('PostSaveCase: Invalid CaseID');
            }else{
                setLoading(true);
                setMessage('Post Update Saving');
                api.transact('PostSaveCase', { CaseID: caseID })
                .then(r => {
                    setLoading(false);
                    setMessage(null);
                })
                .catch(e => {
                    setLoading(false);
                    setMessage(e.message);
                });
            }
        }
    }, [api, setMessage, setLoading])

    return <div>
        <LoadingIndicator test={loading} />
        <TwoSwitch test={message} >
            <p>{message}</p>
        </TwoSwitch>
    </div>
}

export default CaseSave;