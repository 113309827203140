import { useEffect, useState } from 'react';
import { CaseHoldButton, CaseCancelButton } from './CaseStatusButton';
import getAndSaveCaseID from './getAndSaveCaseID';
import { createPortal } from 'react-dom';

const caseStatusContainerElem = document.getElementById('MainContent_SubMainContentPlaceHolder_CaseSplitterPanel_CaseControl_CaseStatusLabel_InputLabel');

const CaseStatusButtons = ({api}) => {
    
    const [error, setError]                 = useState(null);
    const [ loading, setLoading]            = useState(true);

    const caseID                            = getAndSaveCaseID();

    const [caseOb, setCaseOb]               = useState(null);

    const [caseStatusString, setCaseStatusString] = useState(null);

    const [caseOnHold, setCaseOnHold]       = useState(null);
    const [allTreatmentCancelled, setAllTreatmentCancelled] = useState(null);

    useEffect(() => {

        if (typeof caseID === 'number' && caseID > 0) {
            setLoading(true);
            api.transact('CaseLoad', { 'CaseID': caseID })
                .then(r => r.apiResult)
                .then(r => {
                    setLoading(false);
                    setError(null);
                    setCaseOb(r);
                    if(Object.hasOwn(r, 'CaseStatusString')){
                        setCaseStatusString(r.CaseStatusString);
                    }else{
                        setCaseStatusString(getCaseStatusStringHack());
                    }
                })
                .catch(e => {
                    setLoading(false);
                    setError(e.message);
                    setCaseOb(null);
                });
        } else{
            console.log('caseID is not an integer', caseID, typeof caseID);
        }
    }, [api, caseID]);

    const fullCaseStatusString = caseStatusString? (caseStatusString + (caseOnHold? ' (On Hold)': '')): null;

    return <>
        {fullCaseStatusString && createPortal(fullCaseStatusString , caseStatusContainerElem)}
        <CaseHoldButton   {...{api, setError, error, setLoading, loading, caseOb, caseID,
            value: caseOnHold, setValue: setCaseOnHold}} />
        <CaseCancelButton {...{api, setError, error, setLoading, loading, caseOb, caseID,
            value: allTreatmentCancelled, setValue: setAllTreatmentCancelled}} />
    </>
};

const statusTextFromPharos = caseStatusContainerElem?.innerText;
if(statusTextFromPharos) caseStatusContainerElem.innerText = '';
const getCaseStatusStringHack = () => {
    if(caseStatusContainerElem){
        if (/Active/i.test(statusTextFromPharos)) {
            return 'Active';
        } else if (/Discharged/i.test(statusTextFromPharos)) {
            return 'Discharged';
        }else if (/Terminated/i.test(statusTextFromPharos)) {
            return 'Terminated';
        }else return null;
    }
    return null;
}

export default CaseStatusButtons;
