import React, {useEffect, useState, useCallback} from 'react'

const SearchResultWithMarkers = ({searchResult, 
                                  ranking, 
                                  setSearchResultWithMarker, 
                                  searchResultWithMarker,
                                  fetchCoordinateFromGoogleMap}) => {

    const [searchResultWithCoordinatesAndMarkers, setSearchResultWithCoordinatesAndMarkers] = useState([]);

    const updateSearchResultWithGoogleMapsAPI = useCallback((updatedSearchResultArray) =>{
        if(updatedSearchResultArray.length !== 0){
            console.log(updatedSearchResultArray);

            const fetchCoordinates = async() => {
                const updatedLocations = await Promise.all(
                    updatedSearchResultArray.map(async (clinic) => {
                        console.log('clinic coordinates:', clinic.Latitude, clinic.Longitude)
                        if(clinic.Latitude === 0 && clinic.Longitude === 0){
                            console.log(`the search result came back with no coordinates for this clinic. fetching coordinate from google api for ${clinic.Postcode}`)
                            try {
                                const response = await fetchCoordinateFromGoogleMap(clinic.Postcode);
                                return { ...clinic, 'Longitude': response.lng, 'Latitude': response.lat}
                            } catch (error) {
                                console.log(`the search result came back with coordinates for this clinic. The postcode is ${clinic.Postcode} with coordinates ${clinic.Longitude} and ${clinic.Latitude}`)
                                console.error('error', error);
                            }
                            return clinic
                        } else {
                            return clinic
                        }
                    })
                )
                setSearchResultWithCoordinatesAndMarkers(updatedLocations);
            }
            
            fetchCoordinates();
        }
    },[
        fetchCoordinateFromGoogleMap
    ])

    useEffect(()=>{
        if(searchResultWithCoordinatesAndMarkers.length !== 0){
            setSearchResultWithMarker(searchResultWithCoordinatesAndMarkers);
        }
    },[searchResultWithCoordinatesAndMarkers, setSearchResultWithMarker])

    useEffect(()=>{
        const alphabet = [
            'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 
            'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
            'AA', 'BA', 'CA', 'DA', 'EA', 'FA', 'GA', 'HA', 'IA', 'JA', 'KA', 'LA', 'MA', 
            'NA', 'OA', 'PA', 'QA', 'RA', 'SA', 'TA', 'UA', 'VA', 'WA', 'XA', 'YA', 'ZA',
            'AB', 'BB', 'CB', 'DB', 'EB', 'FB', 'GB', 'HB', 'IB', 'JB', 'KB', 'LB', 'MB', 
            'NB', 'OB', 'PB', 'QB', 'RB', 'SB', 'TB', 'UB', 'VB', 'WB', 'XB', 'YB', 'ZB'
        ];

        if(searchResult.length !== 0 && searchResult !== undefined && ranking.length !== 0){
            let updatedSearchResultArray = [];

            console.log('Entering ranking info with searchResult');

            if(searchResult !== undefined){
                
                updatedSearchResultArray = searchResult?.map((clinic, index)=>{
                    let newClinicObject = clinic; 
                    newClinicObject['IndexAlphabet'] = alphabet[index];
                    
                    for( let i = 0 ; i < ranking.length ; i++){
                        if(ranking[i].practiceRankingDescription === clinic.PracticeRankingDescription){
                            newClinicObject['Colour'] = ranking[i].Colour;
                        }
                    }
                    return newClinicObject;
                })  

            }

            updateSearchResultWithGoogleMapsAPI(updatedSearchResultArray);
        }

    },[searchResult, ranking, setSearchResultWithMarker, updateSearchResultWithGoogleMapsAPI])

    return (
        <>
            {/* {'SearchResultWithMarkers component is loaded.'} */}
        </>
    )
}

export default SearchResultWithMarkers