import { TabbedForm, isTrueOrStringTrue }   from "../../lib/iprs-react-library/src/index";
import React            from "react";

/* Wrapper around a Tabbed form that fixes a few issues,
with single questions, not in arrays and 
*/
const TabbedFormWrapper = props => {

    /* remove error messages if the field isn't required.
    The error message should only work when the field is required
    but don't want to change library at the moment */
    const fixQuestion = question => {
        
        if(!isTrueOrStringTrue(question.required, false)){
            delete question.validationWarning;
        }
        
        return question;
    }
    /* Questions come in arrays but sometimes just one question
    might not be wrapped in an array. Just wrap the ones that 
    are not already so */
    const fixTab = tab => {
        const question = tab.Question;
        /* also filter out dummy questions which were used to force 
        an array at the backend */
        const questionArray =
            (Array.isArray(question) ? question : [question])
               .filter(q => q.text !== 'Please ignore')
               .map(q => fixQuestion(q));

        const result = { ...tab, Question: questionArray };
        return result;
    }
    const { tabsFieldDefinitions } = props;
    const newTabsFieldDefinitionsToUse = 
        tabsFieldDefinitions?.map(tab => fixTab(tab));

    return React.createElement(TabbedForm, {...props, 
        tabsFieldDefinitions: newTabsFieldDefinitionsToUse});
}

export default TabbedFormWrapper;
