import React                    from "react";

const Checkboxes = ({items, setItems}) => {

    const handleChange = e => {
        const { target: { name, checked } } = e;
        const newItems = items.map( i => 
            i.name === name?
                ({...i, selected: checked}):
                ({...i}) );
        setItems( newItems );
     }

    return <div className='form__field form__field--full-width' >
        {items?.map?.((i, n) => React.createElement(Checkbox, {...i, onChange: handleChange}))}
    </div>
}

const Checkbox = props => {

    const { name, selected, onChange} = props;

    return <div key={props.id +'div'} className='form__field form__field--full-width' >
        <label className="form__label" htmlFor={name} >{name}</label>
        <input
            type='checkbox'
            name={name}
            id={name}
            checked={selected}
            onChange={onChange} />
    </div>
}

export default Checkboxes;
